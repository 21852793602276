import React from "react";
import {
  StyledLink,
  DownloadText
} from "pages/PanelPage/components/selectedAssessment/assessmentResults/takerResults/questionsTable/Question/styles";
import { Container, FlexContainer } from "components/Atoms/Containers";
import downloadIcon from "assets/downloadIcon";

/**
 * VideoResponse component renders a list of video files and provides download links for each video.
 */
const VideoResponse = ({ answer }) => {
  return (
    <FlexContainer direction={"column"} gap={"16"}>
      {answer?.files && answer.files.length > 0 ? (
        answer.files.map(file => {
          return (
            <FlexContainer key={file.id} direction={"column"} gap={"16"}>
              <video controls width="350">
                <track default kind="captions" srcLang="en" />
                <source src={file.gcs_link} type="video/mp4" />
                There is an issue with the recorded video.
              </video>
              <StyledLink
                href={file.gcs_link}
                download={`${file.file_name}.mp4`}
                mediaType="video/mp4"
              >
                <Container margin="-2px 0 0">{downloadIcon}</Container>
                <DownloadText>Download video ({file.file_name})</DownloadText>
              </StyledLink>
            </FlexContainer>
          );
        })
      ) : (
        <p>Invalid video link or video not available.</p>
      )}
    </FlexContainer>
  );
};

export { VideoResponse };
