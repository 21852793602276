import styled from "styled-components";
import { Body1, Body2, Body3, Subtitle2 } from "components/Atoms/Text";

export const TitleContainer = styled(Body1)`
  border-bottom: ${({ theme }) => `1px solid ${theme.colors.iron}`};
  color: ${({ theme }) => theme.colors.smoky};
  margin: 30px 0 24px 24px;
  padding: 0 0 5px;
  text-transform: capitalize;
`;

export const QuestionNumber = styled(Body3)`
  color: ${({ theme }) => theme.tenantAccent};
  font-size: 26px;
  margin: 0 24px;
`;

export const QuestionText = styled(Subtitle2).attrs({ as: "div" })`
  color: #212629;
  font-weight: normal;
  line-height: 28px;
`;

export const DownloadText = styled(Body2)`
  color: ${({ theme }) => theme.tenantAccent};
  margin-left: 8px;
`;

export const StyledLink = styled.a`
  display: flex;
  text-decoration: none;
`;
